import React, { useRef, FC, useEffect, useCallback } from "react";
import * as L from "leaflet";

import { useMap } from "react-leaflet";

export const MapCanvasOverlay: FC<{
  imageData: string;
  bounds: L.LatLngTuple[];
}> = ({ imageData, bounds }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const map = useMap();
  const rafRef = useRef<number>();
  const imgRef = useRef<HTMLImageElement>();

  // Cache image
  useEffect(() => {
    const img = new Image();
    img.src = imageData;
    imgRef.current = img;
  }, [imageData]);

  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const img = imgRef.current;
    if (!canvas || !img || !img.complete) return;

    rafRef.current = requestAnimationFrame(() => {
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      // Update canvas size
      const mapSize = map.getSize();
      canvas.width = mapSize.x;
      canvas.height = mapSize.y;

      // Get map container offset
      const layerPoint = map.containerPointToLayerPoint([0, 0]);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Apply map transform
      ctx.translate(-layerPoint.x, -layerPoint.y);
      ctx.save();

      // Create clipping path
      ctx.beginPath();
      bounds.forEach(([lat, lng], i) => {
        const point = map.latLngToLayerPoint([lat, lng]);
        if (i === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      });
      ctx.closePath();
      ctx.clip();

      // Draw image aligned with map
      const boundsLatLng = L.latLngBounds(bounds);
      const nw = map.latLngToLayerPoint(boundsLatLng.getNorthWest());
      const se = map.latLngToLayerPoint(boundsLatLng.getSouthEast());

      const width = Math.abs(se.x - nw.x);
      const height = Math.abs(se.y - nw.y);

      ctx.drawImage(img, nw.x, nw.y, width, height);
      ctx.restore();
    });
  }, [map, bounds]);

  useEffect(() => {
    map.on("zoomanim", drawCanvas);

    map.on("move", drawCanvas);
    map.on("moveend", drawCanvas);
    map.on("zoom", drawCanvas);
    map.on("zoomend", drawCanvas);
    map.on("resize", drawCanvas);

    if (imgRef.current?.complete) {
      drawCanvas();
    } else {
      imgRef.current?.addEventListener("load", drawCanvas);
    }

    return () => {
      map.off("move", drawCanvas);
      map.off("moveend", drawCanvas);
      map.off("zoomend", drawCanvas);
      map.off("resize", drawCanvas);
      imgRef.current?.removeEventListener("load", drawCanvas);

      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [map, drawCanvas]);

  return (
    <div className="absolute inset-0" style={{ zIndex: 400 }}>
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          zIndex: 400,
          transition: "opacity 200ms ease-in-out",
        }}
        className="region-overlay"
      />
    </div>
  );
};
