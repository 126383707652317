import "./MapSettings.css";
import React, { FC, Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

interface MapSettingsProps {
  maxCloudCoverage: number;
  onMaxCloudCoverageChange: (value: number) => void;
}

export const MapSettings: FC<MapSettingsProps> = ({
  maxCloudCoverage,
  onMaxCloudCoverageChange,
}) => {
  const { t } = useTranslation("translation");
  const [localCloudCoverage, setLocalCloudCoverage] =
    useState(maxCloudCoverage);

  useEffect(() => {
    setLocalCloudCoverage(maxCloudCoverage);
  }, [maxCloudCoverage]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className="flex items-center gap-2 px-3 py-2 bg-white/90 hover:bg-white/95 rounded-lg shadow-sm transition-all duration-200">
            <Cog6ToothIcon
              className={`h-5 w-5 ${open ? "text-blue-500" : "text-gray-500"}`}
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            afterLeave={() => {
              if (localCloudCoverage !== maxCloudCoverage) {
                onMaxCloudCoverageChange(localCloudCoverage);
              }
            }}
          >
            <Popover.Panel className="map-settings-container absolute right-0 z-50 mt-2 w-72 rounded-xl bg-white shadow-lg ring-1 ring-black/5 p-4 min-w-[300px]">
              <div className="space-y-5">
                {" "}
                {/* Increased space between sections */}
                <h3 className="text-md font-semibold text-gray-900 mb-4">
                  {t("mapSettings.title")}
                </h3>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="default-range"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {t("mapSettings.maxCloudCoverage")}
                    </label>
                    <span className="text-sm font-semibold text-gray-900 tabular-nums">
                      {localCloudCoverage}%
                    </span>
                  </div>

                  <input
                    id="default-range"
                    type="range"
                    min="0"
                    max="100"
                    value={localCloudCoverage}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    onChange={(e) =>
                      setLocalCloudCoverage(Number(e.target.value))
                    }
                  />

                  <p className="text-xs text-gray-500 leading-relaxed mt-1">
                    {t("mapSettings.cloudCoverageHint")}
                  </p>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
