import React from "react";

interface LoadingOverlayProps {
  show: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ show }) => {
  if (!show) return null;

  return (
    <div className="absolute inset-0 z-[1000] pointer-events-none">
      <div className="absolute inset-0 backdrop-blur-[2px] bg-slate-50/5" />

      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-16 h-16">
          {/* Outermost ring - slow spin */}
          <div className="absolute inset-0 rounded-full border-[3px] border-transparent border-t-indigo-500/90 animate-[spin_2s_ease-in-out_infinite]" />

          {/* Middle ring - medium spin */}
          <div className="absolute inset-[4px] rounded-full border-[3px] border-transparent border-t-sky-500/80 animate-[spin_1.5s_ease-in-out_infinite]" />

          {/* Inner ring - fast spin */}
          <div className="absolute inset-[8px] rounded-full border-[3px] border-transparent border-t-cyan-400/70 animate-[spin_1s_ease-in-out_infinite]" />

          {/* Static gradient background */}
          <div className="absolute inset-0 rounded-full border-[3px] bg-gradient-to-tr from-slate-100/10 to-white/5" />

          {/* Center dot */}
          <div className="absolute inset-[6px] rounded-full bg-gradient-to-br from-slate-50/20 to-slate-50/5 backdrop-blur-sm" />
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
