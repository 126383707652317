import React, { FC, useMemo, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import { Sonda, deleteSonda } from "../store/sonda/sondaSlice";
import { useAppDispatch } from "../store";
import AlertModal from "./AlertModal";
import {
  InformationCircleIcon,
  PencilSquareIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { updateSondaName } from "../api/sonda";
import { ClipboardIcon } from "@heroicons/react/20/solid";

interface SondaProps {
  sonda: Sonda;
  fieldId: number;
}

const SondaListItem: FC<SondaProps> = ({ sonda, fieldId }) => {
  const { t } = useTranslation("translation");

  const dispatch = useAppDispatch();
  const [nameEditing, setNameEditing] = useState(false);
  const [sondaName, setSondaName] = useState(sonda.name);

  const nameInputRef = useRef<HTMLInputElement>(null);

  const deleteSondaOnClick = (event) => {
    event.preventDefault();
    dispatch(deleteSonda(sonda.sondaCode, fieldId));
  };

  const handleEditNameClick = () => {
    setNameEditing(true);

    // Focus on the input element few milisecond after
    // it has been rendered, otherwise it won't be able
    // to focus to the element
    setTimeout(() => {
      nameInputRef?.current?.focus();
    }, 100);
  };

  const handleUpdateName = () => {
    // Method to update the edited name in the Backend

    // Do not allow empty name updates
    if (sondaName.length === 0) {
      toast.warn(t("errorMessages.sondaNameCanNotBeEmpty"));
      setSondaName(sonda.name);

      // Only call backend API when the name has been changed
    } else if (sondaName !== sonda.name) {
      // Only update the name only when its changed
      updateSondaName(sonda.id, sondaName)
        .then(() => {
          toast.success(t("updateSondaMessages.sondaNameUpdatedSuccessfully"));
        })
        .catch(() => {
          toast.error(t("updateSondaMessages.couldNotUpdateName"));
          setSondaName(sonda.name);
        });
    }
    setNameEditing(false);
  };

  const sondaHasErrors = useMemo<boolean>(() => {
    return (
      sonda.measurements?.[0]?.error_status &&
      sonda.measurements?.[0].error_status.length > 0
    );
  }, [sonda]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(sonda.sondaCode)
      .then(() => {
        toast.success(t("common.copiedToClipboard"));
      })
      .catch(() => {
        toast.error(t("common.copyToClipboardFailed"));
      });
  };

  return (
    <tr key={sonda.sondaCode}>
      <td
        className="whitespace-nowrap text-sm font-medium"
        style={{ minWidth: "185px" }}
      >
        {nameEditing ? (
          <input
            className="rounded-md border border-gray-300 shadow-sm focus:border-amber-300 focus:outline-none focus:ring-amber-300 sm:text-sm w-44 max-w-44"
            id={sonda.sondaCode}
            maxLength={16}
            value={sondaName}
            name={sonda.sondaCode}
            onChange={(e) => {
              setSondaName(e.target.value);
            }}
            type="text"
            ref={nameInputRef}
            onBlur={() => {
              handleUpdateName();
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleUpdateName();
              }
            }}
            required
          />
        ) : (
          <div className="flex flex-row  py-4 px-3">
            <span className="font-bold mr-2" onClick={handleEditNameClick}>
              {sondaName}
            </span>
            <PencilSquareIcon height={19} onClick={handleEditNameClick} />
          </div>
        )}
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm">
        <span className="text-gray-500">{t("fieldListPage.battery")} </span>
        <span className="font-bold">
          {sonda.measurements?.[0]?.battery
            ? `${sonda.measurements?.[0]?.battery}%`
            : t("common.noData")}
        </span>
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm">
        <span className="text-gray-500">{t("fieldListPage.connection")} </span>
        <span className="font-bold">
          {sonda.measurements?.[0]?.connection
            ? `${sonda.measurements?.[0]?.connection}%`
            : t("common.noData")}
        </span>
      </td>

      <td className="whitespace-nowrap py-4 px-3 text-sm">
        <Popup
          trigger={
            sondaHasErrors ? (
              <ExclamationTriangleIcon color="#cc6600" height={24} />
            ) : (
              <InformationCircleIcon color="#FCCD34" height={24} />
            )
          }
          position="top center"
          keepTooltipInside
        >
          <div
            className="bg-white border rounded-lg shadow-lg px-2 py-2"
            // className="transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-2"
          >
            <div className="bg-white-200 rounded-lg px-2 py-1">
              <div className="flex items-center">
                <span className="text-gray-400">Sonda code:</span>
                <span className="px-2 text-gray-400">{sonda.sondaCode}</span>
                <ClipboardIcon
                  className="h-5 w-5 text-gray-400 hover:text-gray-600 cursor-pointer ml-1 inline-block"
                  onClick={copyToClipboard}
                />
              </div>
            </div>
            {sondaHasErrors ? (
              <ul className="px-2 py-1 bg-red-50 rounded-lg">
                {sonda.measurements?.[0]?.error_status
                  ?.filter((error) => error != null && error != undefined)
                  .map((error, index) => (
                    <li className="pt-1 text-sm text-red-400" key={index}>
                      <div className="flex flex-row items-center gap-2">
                        <ExclamationTriangleIcon height={24} color="red" />
                        <span>
                          {t(`sondaErrors.${error}`, {
                            defaultValue: t("common.generalError"),
                          })}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        </Popup>
      </td>
      <td className="whitespace-nowrap py-4 px-3 text-sm">
        <AlertModal deleteSondaOnClick={deleteSondaOnClick} />
      </td>
    </tr>
  );
};

export default SondaListItem;
