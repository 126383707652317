import React, {
  useRef,
  FC,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import * as L from "leaflet";
import { customizeToolbarSettings } from "../utils/mapToolbarSettings";

import { MapContainer, TileLayer, FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import Popup from "reactjs-popup";

import { Language, drawLocales } from "leaflet-draw-locales";
import {
  addFieldRegion,
  deleteFieldRegion,
  getFieldRegions,
  updateFieldRegion,
} from "../api/field_region";
import { Field } from "../store/fields/fieldsSlice";
import authorize from "../auth/sentinelHubAuth";
import {
  ApiType,
  BBox,
  CRS_EPSG4326,
  MimeTypes,
  S2L2ALayer,
  Tile,
} from "@sentinel-hub/sentinelhub-js";
import "./calendar.css";

import { toast } from "react-toastify";
import { scripts } from "../utils/scriptsData";
import { Menu, Transition } from "@headlessui/react";
import { MapCanvasOverlay } from "./MapCanvasOverlay";
import Calendar from "react-calendar";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { SunIcon, CloudIcon } from "@heroicons/react/24/outline";
import { MapSettings } from "./MapSettings";
import LoadingOverlay from "./LoadingOverlay";

customizeToolbarSettings();

const CLOUD_COVERAGE = {
  EXCELLENT: { max: 10, color: "amber" },
  GOOD: { max: 30, color: "sky" },
  MODERATE: { max: 50, color: "blue" },
  POOR: { max: 100, color: "gray" },
} as const;

const MapControl = ({ language }) => {
  const map = useMap();
  const { t } = useTranslation("translation");

  useEffect(() => {
    drawLocales(language as Language);
  }, [language, map]);

  return null;
};

interface FieldMapProps {
  field: Field;
  height?: string;
  width?: string;
  measurementType: string;
  children: ReactNode;
}

interface FieldRegion {
  id: number;
  fieldId: number;
  polygon: { type: string; coordinates: L.LatLngTuple[][] };
}

const MapStatus: FC<{
  status: "idle" | "loading" | "ready" | "error";
  onCancel?: () => void;
  onRetry?: () => void;
}> = ({ status, onCancel, onRetry }) => {
  return (
    <div className="flex items-center gap-2 px-3 py-1.5 bg-white/90 rounded-full shadow-sm">
      <div
        className={`h-2 w-2 rounded-full ${
          status === "loading"
            ? "bg-amber-500 animate-pulse"
            : status === "ready"
            ? "bg-emerald-500"
            : status === "error"
            ? "bg-red-500"
            : "bg-gray-400"
        }`}
      />
      <span className="text-xs font-medium text-gray-600 capitalize">
        {status}
      </span>
      {status === "loading" && onCancel && (
        <button
          onClick={onCancel}
          className="ml-2 text-xs font-medium text-red-600 hover:text-red-700"
        >
          Cancel
        </button>
      )}
      {status === "error" && onRetry && (
        <button
          onClick={onRetry}
          className="ml-2 text-xs font-medium text-blue-600 hover:text-blue-700"
        >
          Retry
        </button>
      )}
    </div>
  );
};

const FieldMap: FC<FieldMapProps> = ({
  field,
  height = "42rem",
  width = "100%",
  children,
  measurementType,
}) => {
  const [fieldRegions, setFieldRegions] = useState<FieldRegion[]>([]);
  const [selectedScriptName, setSelectedScriptName] =
    useState<string>("moistureIndex");
  const [imagesFetching, setImagesFetching] = useState<boolean>(false);
  const { t, i18n } = useTranslation("translation");
  const isFetchingCatalog = useRef(false);
  const abortController = useRef<AbortController | null>(null);

  // overlay image urls
  const [regionOverlays, setRegionOverlays] = useState<{
    [key: number]: {
      imageUrl: string;
      latLngBounds: L.LatLngTuple[];
    };
  }>({});

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);
  const [statsFetchedMonths, setStatsFetchedMonths] = useState<Set<string>>(
    new Set()
  );
  const [mapStatsData, setMapStatsData] = useState<Map<string, Tile>>(
    new Map()
  );
  const [isMonthLoading, setIsMonthLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [maxCloudCoverage, setMaxCloudCoverage] = useState<number>(80);

  const featureGroupRef = useRef<L.FeatureGroup>(null);
  const canvasOverlaysRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getFieldRegions(field.fieldId).then(setFieldRegions);
    authorize();
  }, [field.fieldId]);

  useEffect(() => {
    if (selectedDate) {
      loadImageryForDate(selectedDate);
    }
  }, [fieldRegions, selectedScriptName, selectedDate]);

  const blobToDataURI = (
    blob: Blob
  ): Promise<string | ArrayBuffer | null | undefined> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.onerror = (error) => {
        reject(new Error("Failed to convert blob to data URI: "));
      };
      reader.readAsDataURL(blob);
    });
  };

  const fetchImageCatalog = async (
    regions: FieldRegion[],
    currentDate: Date
  ) => {
    if (isFetchingCatalog.current) {
      return;
    }

    const currentMonth = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}`;

    if (statsFetchedMonths.has(currentMonth)) {
      return;
    }

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    for (const region of regions) {
      const coordinates = region.polygon.coordinates[0];

      for (let i = 0; i < coordinates.length; i++) {
        const [lng, lat] = coordinates[i];

        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
      }
    }

    const fromTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const toTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const bbox = new BBox(CRS_EPSG4326, minLng, minLat, maxLng, maxLat);

    const layer = new S2L2ALayer({
      evalscript: scripts[selectedScriptName],
      maxCloudCoverPercent: maxCloudCoverage,
    });

    try {
      isFetchingCatalog.current = true;
      const { tiles } = await layer.findTiles(bbox, fromTime, toTime, 30);

      if (tiles.length > 0) {
        const statsData = new Array<[string, Tile]>();

        tiles.forEach((tile) => {
          const tileDate = new Date(tile.sensingTime);
          const dateKey = tileDate.setHours(0, 0, 0, 0).toString();
          statsData.push([dateKey, tile]);
        });
        setMapStatsData(
          (prev) => new Map([...Array.from(prev.entries()), ...statsData])
        );

        // Add month to fetched months set
        setStatsFetchedMonths((prev) => new Set([...prev, currentMonth]));
      }
    } finally {
      isFetchingCatalog.current = false;
    }
  };

  const cancelLoading = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
      abortController.current = null;
      setIsMonthLoading(false);
      setImagesFetching(false);
    }
  }, []);

  const getSatelliteImagery = async (
    region: FieldRegion,
    date: Date,
    abortSignal?: AbortSignal
  ) => {
    const layer = new S2L2ALayer({
      evalscript: scripts[selectedScriptName],
      maxCloudCoverPercent: maxCloudCoverage,
      // @sentinel-hub/sentinelhub-js is missing upsampling type, so we have to ignore type error
      // it inherits it from AbstractSentinelHubV3Layer, however the type is not inherited
      // this might get fixed in the future updates of the library
      // @ts-ignore
      upsampling: "BICUBIC",
    });

    const fromTime = new Date(date);
    fromTime.setHours(0, 0, 0, 0);
    const toTime = new Date(date);
    toTime.setHours(23, 59, 59, 999);

    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    const coordinates = region.polygon.coordinates[0];

    for (let i = 0; i < coordinates.length; i++) {
      const [lng, lat] = coordinates[i];

      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    }

    const bbox = {
      minLng: minLng,
      minLat: minLat,
      maxLng: maxLng,
      maxLat: maxLat,
    };

    const getMapParams = {
      bbox: new BBox(
        CRS_EPSG4326,
        bbox.minLng,
        bbox.minLat,
        bbox.maxLng,
        bbox.maxLat
      ),
      fromTime: fromTime,
      toTime: toTime,
      width: 512,
      height: 343.697,
      format: MimeTypes.JPEG,
      layerId: "test",
      signal: abortSignal,
    };

    try {
      const responseBlob = await layer.getMap(getMapParams, ApiType.PROCESSING);

      if (responseBlob instanceof Blob && responseBlob.type === "image/jpeg") {
        const dataURI = await blobToDataURI(responseBlob);
        if (typeof dataURI === "string") {
          setRegionOverlays((overlays) => ({
            ...overlays,
            [region.id]: {
              imageUrl: dataURI,
              latLngBounds: coordinates.map(([lng, lat]) => [lat, lng]),
            },
          }));
        }
      } else {
        console.warn("Unexpected response format:", responseBlob);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        return;
      }
      setFetchError("error");
    }
  };

  useEffect(() => {
    setMapStatsData(new Map());
    setStatsFetchedMonths(new Set());
    setRegionOverlays({});

    // If there's not a selected date, do nothing
    if (!selectedDate) {
      return;
    }

    setIsMonthLoading(true);
    setFetchError(null);

    fetchImageCatalog(fieldRegions, selectedDate)
      .then(() => {
        return loadImageryForDate(selectedDate);
      })
      .catch((error) => {
        console.error("Failed to fetch catalog:", error);
      })
      .finally(() => {
        setIsMonthLoading(false);
      });
  }, [maxCloudCoverage]);

  useEffect(() => {
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();

      fieldRegions.forEach((region) => {
        const polygon = L.polygon(
          convertCoordinates(region.polygon.coordinates),
          {
            // Border style
            color: "#FFFFFF",
            weight: 1.5, // Border width

            // Interactive states
            fillRule: "evenodd",
            dashArray: "", // Solid line
            lineCap: "round",
            lineJoin: "round",

            // Interactive behavior
            interactive: true,
            bubblingMouseEvents: true,
          }
        );
        polygon.feature = {
          type: "Feature",
          properties: {
            region_id: region.id,
          },
          geometry: polygon.toGeoJSON().geometry,
        };

        featureGroupRef.current?.addLayer(polygon);
      });
    }
  }, [fieldRegions]);

  const convertLayerToGeoJSON = (layer: L.Layer): GeoJSON.Polygon => {
    if (layer instanceof L.Polygon) {
      const coordinates = layer.getLatLngs()[0];

      // Convert LatLng array to coordinate pairs and ensure polygon is closed
      const coordinateList = (coordinates as L.LatLng[]).map((coord) => [
        coord.lng, // GeoJSON uses [longitude, latitude] order
        coord.lat,
      ]);

      // Close the polygon by adding first point at end if needed
      if (
        coordinateList[0][0] !== coordinateList[coordinateList.length - 1][0] ||
        coordinateList[0][1] !== coordinateList[coordinateList.length - 1][1]
      ) {
        coordinateList.push(coordinateList[0]);
      }

      return {
        type: "Polygon",
        coordinates: [coordinateList], // Wrap in array for polygon format
      };
    }
    throw new Error("Layer must be a polygon");
  };

  const handleAreaCreated = async (e) => {
    const { layer } = e;

    try {
      const response = await addFieldRegion(
        field.fieldId,
        convertLayerToGeoJSON(layer)
      );
      setFieldRegions((regions) => [...regions, response]);

      if (featureGroupRef.current) {
        featureGroupRef.current.removeLayer(layer);
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const handleEdited = async (e) => {
    const layers = e.layers.getLayers();
    if (!layers.length) return;

    const updatedRegions: { [key: number]: FieldRegion } = {};
    for (const layer of layers) {
      // const regionId = layer.options.id;
      const regionId = (layer as L.Polygon).feature?.properties?.region_id;

      const coordinates = (layer.getLatLngs()[0] as L.LatLng[]).map(
        ({ lat, lng }: L.LatLng) => [lng, lat]
      );

      const polygon = {
        type: "Polygon",
        coordinates: [coordinates],
      };

      try {
        const updated = await updateFieldRegion(regionId, polygon);
        updatedRegions[regionId] = updated;
      } catch (err) {
        console.error("Failed to update region:", err);
      }
    }

    // Refresh regions with updated regions
    setFieldRegions((regions) => regions.map((r) => updatedRegions[r.id] || r));
  };

  const handleDeleted = async (e) => {
    const layers = e.layers.getLayers();
    if (!layers.length) return;

    const polyIds: number[] = [];
    for (const layer of layers) {
      try {
        const polygonId = (layer as L.Polygon).feature?.properties?.region_id;

        if (!polygonId) {
          console.error("No polygon ID found");
          return;
        }

        await deleteFieldRegion(polygonId);
        polyIds.push(polygonId);
      } catch (err) {
        console.error("Failed to delete region:", err);
      }
    }

    setFieldRegions((regions) =>
      regions.filter((r) => !polyIds.includes(r.id))
    );
    setRegionOverlays((overlays) => {
      const newOverlays = { ...overlays };
      polyIds.forEach((id) => delete newOverlays[id]);
      return newOverlays;
    });
  };

  const convertCoordinates = (
    coordinates: L.LatLngTuple[][]
  ): L.LatLngTuple[] => {
    // GeoJSON uses [longitude, latitude]
    // Leaflet uses [latitude, longitude]
    return coordinates[0].map((coord) => [coord[1], coord[0]]);
  };

  const handleEditStart = () => {
    if (canvasOverlaysRef.current) {
      canvasOverlaysRef.current.style.display = "none";
    }
  };

  const handleEditEnd = () => {
    if (canvasOverlaysRef.current) {
      canvasOverlaysRef.current.style.display = "block";
    }
  };

  const dateFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const onActiveStartDateChange = async ({
    activeStartDate,
  }: {
    activeStartDate: Date;
  }) => {
    const monthKey = `${activeStartDate.getFullYear()}-${String(
      activeStartDate.getMonth() + 1
    ).padStart(2, "0")}`;

    if (!statsFetchedMonths.has(monthKey) && !isFetchingCatalog.current) {
      setIsMonthLoading(true);
      setFetchError(null);

      try {
        await fetchImageCatalog(fieldRegions, activeStartDate);
        setStatsFetchedMonths((prev) => new Set([...prev, monthKey]));
      } catch (error) {
        setFetchError(t("mapDetails.fetchError"));
        console.error("Failed to fetch catalog:", error);
      } finally {
        setIsMonthLoading(false);
      }
    }
  };

  const loadImageryForDate = async (date: Date) => {
    try {
      setImagesFetching(true);

      setRegionOverlays({}); // Reset existing overlays
      abortController.current = new AbortController();

      await Promise.all(
        fieldRegions.map((region) =>
          getSatelliteImagery(region, date, abortController.current?.signal)
        )
      );
    } catch (error) {
      console.error("Failed to load imagery:", error);
      toast.error(t("mapDetails.imageryLoadError"));
      setFetchError(t("mapDetails.imageryLoadError"));
    } finally {
      setImagesFetching(false);
    }
  };

  return (
    <div className="relative">
      <div
        className="flex flex-row items-center py-2 justify-end"
        style={{ justifyContent: "flex-end" }}
      >
        <MapStatus
          status={
            isMonthLoading || imagesFetching
              ? "loading"
              : fetchError
              ? "error"
              : "ready"
          }
          onCancel={
            isMonthLoading || imagesFetching ? cancelLoading : undefined
          }
          onRetry={
            fetchError
              ? () => {
                  setFetchError(null);
                  if (selectedDate) {
                    loadImageryForDate(selectedDate);
                  } else {
                    console.log("No date");
                  }
                }
              : undefined
          }
        />
        <Popup
          trigger={
            <button className="flex items-center gap-2 px-4 py-2 bg-white/90 hover:bg-white/95 rounded-lg shadow-sm transition-all duration-200">
              <span className="text-sm font-medium text-gray-700">
                {selectedDate
                  ? selectedDate.toLocaleDateString(
                      i18n.language,
                      dateFormatOptions
                    )
                  : t("fieldDetailsPage.selectCalendarDate")}
              </span>
              <CalendarIcon className="h-5 w-5 text-gray-500" />
            </button>
          }
          overlayClassName="calendar-popup-overlay"
          position="bottom right"
          keepTooltipInside=".field-details-map"
          className="calendar-popup"
          overlayStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          contentStyle={{
            width: "100%",
            maxWidth: "350px",
            margin: "16px",
          }}
          onOpen={() => {
            const activeStartDate = selectedDate || new Date();
            onActiveStartDateChange({ activeStartDate });
          }}
        >
          {
            // @ts-ignore
            (close) => {
              return (
                <div className="calendar-container p-0">
                  {isMonthLoading && (
                    <div className="absolute inset-0 bg-white/50 flex items-center justify-center z-50">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    </div>
                  )}

                  <Calendar
                    locale={i18n.language}
                    view="month"
                    onActiveStartDateChange={onActiveStartDateChange}
                    onChange={(date) => {
                      if (!date || !(date instanceof Date)) {
                        return;
                      }

                      const dateKey = date.setHours(0, 0, 0, 0).toString();
                      const tileData = mapStatsData.get(dateKey);

                      if (!tileData) return;

                      setSelectedDate(date);
                      setCalendarVisible(false);
                      close();
                    }}
                    value={selectedDate}
                    maxDate={new Date()}
                    tileDisabled={({ date }) => {
                      const dateKey = date.setHours(0, 0, 0, 0).toString();
                      return !mapStatsData.has(dateKey);
                    }}
                    tileContent={({ date, view }) => {
                      const dateKey = date.setHours(0, 0, 0, 0).toString();
                      const tileData = mapStatsData.get(dateKey);

                      if (view !== "month") return null;

                      if (!tileData) {
                        return (
                          <div className="date-container">
                            <span className="text-gray-300">·</span>
                          </div>
                        );
                      }

                      const cloudCoverage = tileData.meta.cloudCoverPercent;
                      if (typeof cloudCoverage !== "number") return null;

                      return (
                        <>
                          {/* Weather Icon */}
                          <div className="weather-icon-container">
                            {cloudCoverage <= CLOUD_COVERAGE.EXCELLENT.max ? (
                              <div className="relative">
                                <SunIcon
                                  className="h-5 w-5 text-amber-500 drop-shadow-sm"
                                  title={`Excellent visibility - ${cloudCoverage.toFixed(
                                    1
                                  )}% cloud coverage`}
                                />
                                <div className="absolute inset-0 bg-amber-400/30 rounded-full" />
                              </div>
                            ) : cloudCoverage <= CLOUD_COVERAGE.GOOD.max ? (
                              <CloudIcon
                                className="h-5 w-5 text-sky-500 drop-shadow-sm"
                                title={`Good visibility - ${cloudCoverage.toFixed(
                                  1
                                )}% cloud coverage`}
                              />
                            ) : cloudCoverage <= CLOUD_COVERAGE.MODERATE.max ? (
                              <CloudIcon
                                className="h-5 w-5 text-blue-400 drop-shadow-sm"
                                title={`Moderate visibility - ${cloudCoverage.toFixed(
                                  1
                                )}% cloud coverage`}
                              />
                            ) : (
                              <CloudIcon
                                className="h-5 w-5 text-gray-400 drop-shadow-sm"
                                title={`Limited visibility - ${cloudCoverage.toFixed(
                                  1
                                )}% cloud coverage`}
                              />
                            )}
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              );
            }
          }
        </Popup>

        <Menu as="div" className="relative ml-2">
          <Menu.Button className="flex items-center gap-2 px-4 py-2 bg-white/90 hover:bg-white/95 rounded-lg shadow-sm transition-all duration-200">
            <span className="text-sm font-medium text-gray-700">
              {t(`scriptNames.${selectedScriptName}`)}
            </span>
            <svg
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[1000]">
              <div className="py-1">
                {Object.keys(scripts).map((scriptName) => (
                  <Menu.Item key={scriptName}>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-gray-50" : ""
                        } group flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50`}
                        onClick={() => {
                          setSelectedScriptName(scriptName);
                        }}
                      >
                        {t(`scriptNames.${scriptName}`)}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <MapSettings
          maxCloudCoverage={maxCloudCoverage}
          onMaxCloudCoverageChange={(value) => {
            setMaxCloudCoverage(value);
            cancelLoading();
          }}
        />
      </div>
      <MapContainer
        key={i18n.language}
        className="z-10"
        center={{
          lat: field.lat,
          lng: field.lng,
        }}
        zoom={15}
        zoomControl={true}
        scrollWheelZoom={true}
        dragging={true}
        keyboard={true}
        style={{ height: height, width: width }}
        preferCanvas={true}
      >
        <MapControl language={i18n.language} />
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          maxZoom={19}
          minZoom={15}
        />
        {children}
        <FeatureGroup ref={featureGroupRef}>
          <EditControl
            position="topright"
            onCreated={handleAreaCreated}
            onEdited={handleEdited}
            onDeleted={handleDeleted}
            onEditStart={handleEditStart}
            onEditStop={handleEditEnd}
            draw={{
              rectangle: true,
              polyline: false,
              circle: false,
              marker: false,
              polygon: true,
              circlemarker: false,
            }}
            edit={{
              edit: {
                selectedPathOptions: {
                  maintainColor: true,
                  moveMarkers: false, // Disable extra marker points
                },
              },
              // hiding remove toolbar options from remove button in leaflet.scss
              remove: true,
              poly: {
                allowIntersection: false,
              },
            }}
          />
        </FeatureGroup>
        <LoadingOverlay show={imagesFetching} />
        <div ref={canvasOverlaysRef} className="pointer-events-none">
          {Object.entries(regionOverlays).map(
            ([regionId, { imageUrl, latLngBounds }]) => (
              <MapCanvasOverlay
                key={regionId}
                imageData={imageUrl}
                bounds={latLngBounds}
              />
            )
          )}
        </div>
      </MapContainer>
    </div>
  );
};

export default FieldMap;
