import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LANGUAGE_STORAGE_KEY } from "../i18n";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LanguageButton: FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lng);
  };

  const getFlagIcon = (language) => {
    switch (language) {
      case "en":
        return require("../assets/icons/flags/us.svg");
      case "es":
        return require("../assets/icons/flags/es.svg");
      case "de":
        return require("../assets/icons/flags/de.svg");
      default:
        return require("../assets/icons/flags/us.svg");
    }
  };

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="inline-flex items-center rounded-md border-1 border px-2 py-1 text-sm font-medium text-gray-900">
          <img
            src={getFlagIcon(i18n.language)}
            className="mr-1"
            alt={i18n.language}
          />
          {i18n.language.toUpperCase()}
          <ChevronDownIcon className="block h-4 w-4 ml-2" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-20 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => changeLanguage("en")}
                className={classNames(
                  active ? "flex items-center w-full bg-gray-100" : "",
                  "flex items-center w-full px-4 py-2 text-sm text-gray-700"
                )}
              >
                <img
                  src={`${require("../assets/icons/flags/us.svg")}`}
                  className="mr-1"
                  alt="english"
                />{" "}
                EN{" "}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => changeLanguage("es")}
                className={classNames(
                  active ? "flex items-center w-full bg-gray-100" : "",
                  "flex items-center w-full px-4 py-2 text-sm text-gray-700"
                )}
              >
                <img
                  src={`${require("../assets/icons/flags/es.svg")}`}
                  className="mr-1"
                  alt="spanish"
                />
                ES{" "}
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => changeLanguage("de")}
                className={classNames(
                  active ? "flex items-center w-full bg-gray-100" : "",
                  "flex items-center w-full px-4 py-2 text-sm text-gray-700"
                )}
              >
                <img
                  src={`${require("../assets/icons/flags/de.svg")}`}
                  className="mr-1"
                  alt="german"
                />
                DE{" "}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageButton;
