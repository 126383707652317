import { axiosClient } from ".";

export const getEfficiencyCalculation = async (
  fieldId: string,
  sondaCode: string,
  measurement: number,
  date?: Date
) => {
  const response = await axiosClient.get(`/api/v1/fields/efficiency-rating`, {
    params: {
      field_id: fieldId,
      sonda_code: sondaCode,
      measurement,
      date,
    },
  });

  return response.data;
};
