import React, { StrictMode } from "react";
import { Provider } from "react-redux";

import AppRouter from "./AppRouter";
import AuthProvider from "./auth/provider";
import store from "./store";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <StrictMode>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme="light"
          style={{
            zIndex: 9999,
            top: "1rem",
          }}
          toastStyle={{
            maxWidth: "90vw",
            minWidth: "200px",
            margin: "0 auto",
          }}
        />
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </AuthProvider>
    </StrictMode>
  );
};

export default App;
