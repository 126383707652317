import React from "react";
import { useState } from "react";
import { Sonda } from "../store/sonda/sondaSlice";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { CalendarIcon } from "@heroicons/react/20/solid";
import Calendar from "react-calendar";
import { getEfficiencyCalculation } from "../api/fields";
import { DefaultTFuncReturn } from "i18next";
import "./calendar.css";

interface FieldPerformanceMeasureParams {
  fieldId: string;
  sondas: Sonda[];
}

interface FormErrors {
  application?: string | DefaultTFuncReturn;
  sondaCode?: string | DefaultTFuncReturn;
}

export const FieldPerformanceMeasure = ({
  fieldId,
  sondas,
}: FieldPerformanceMeasureParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [responseError, setResponseError] = useState<
    string | DefaultTFuncReturn
  >();

  const [formData, setFormData] = useState<{
    lastApplicationDate?: Date;
    application?: number;
    sondaCode?: string;
  }>({});
  const [performance, setPerformance] = useState<
    | {
        efficiency: string;
        rate: string;
      }
    | undefined
  >();

  const { t, i18n } = useTranslation("translation");

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (formData.application === undefined) {
      newErrors.application = t(
        "fieldPerformanceMeasureForm.applicationRequired"
      );
    }

    if (formData.sondaCode === undefined) {
      newErrors.sondaCode = t("fieldPerformanceMeasureForm.sondaRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await getEfficiencyCalculation(
        fieldId,
        formData.sondaCode!,
        formData.application!,
        formData.lastApplicationDate
      );

      if (response.rate && response.efficiency) {
        setPerformance({
          efficiency: Number.parseFloat(response.efficiency).toFixed(3),
          rate: Number.parseFloat(response.rate).toFixed(3),
        });
      } else {
        setResponseError(t("fieldPerformanceMeasureForm.unknownError"));
      }
    } catch (error) {
      if (error?.response?.data?.error == "INSUFFICIENT_DATA") {
        setResponseError(
          t("fieldPerformanceMeasureForm.insufficientDataError")
        );
      } else {
        setResponseError(t("fieldPerformanceMeasureForm.unknownError"));
      }
      setPerformance(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLastApplicationChange = (event) => {
    setFormData((data) => ({ ...data, application: event.target.value }));
  };

  const handleDateChange = (value) => {
    if (value instanceof Date) {
      setFormData((data) => ({ ...data, lastApplicationDate: value }));
    }
  };

  const handleSondaSelectChange = (event) => {
    setFormData((data) => ({ ...data, sondaCode: event.target.value }));
  };

  const dateFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="grid gap-8 mb-6 grid-cols-1 md:grid-cols-2 w-full">
      <div className="gap-6">
        <form
          className="w-full max-w-lg flex flex-col gap-4"
          onSubmit={handleFormSubmit}
        >
          <div>
            <label
              htmlFor="last-application"
              className="block mb-2 text-sm font-bold text-gray-700 dark:text-white"
            >
              {t("fieldPerformanceMeasureForm.myLastApplicationWas")}
            </label>

            <div className="flex flex-col">
              <input
                type="number"
                id="last-application"
                required
                value={formData.application ?? 0}
                onChange={handleLastApplicationChange}
                className={`w-full px-3 py-2 border rounded-md transition-colors duration-200
                  ${
                    errors.application
                      ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:border-green-500 focus:ring-green-500"
                  }`}
              />
              {errors.application && (
                <p className="mt-1 text-xs text-red-500 font-medium">
                  {errors.application}
                </p>
              )}
              <p
                className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="application-helper"
              >
                Kg N/ha
              </p>
            </div>
          </div>

          <div>
            <label
              htmlFor="date-select"
              className="block mb-2 text-sm font-bold text-gray-700 dark:text-white"
            >
              {t("fieldPerformanceMeasureForm.lastApplicationDate")}
            </label>
            <Popup
              trigger={
                <div className="flex flex-row gap-2 rounded-md cursor-pointer gap-2 items-center">
                  <CalendarIcon className="block h-5 w-5" />
                  {formData.lastApplicationDate ? (
                    <span>
                      {formData.lastApplicationDate.toLocaleDateString(
                        i18n.language,
                        dateFormatOptions
                      )}
                    </span>
                  ) : (
                    <span>{t("fieldPerformanceMeasureForm.lastMonth")}</span>
                  )}
                </div>
              }
              overlayClassName="calendar-popup-overlay"
              className="calendar-popup"
              arrow={false}
              closeOnDocumentClick
              closeOnEscape
              position="bottom left"
              overlayStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              {
                // @ts-ignore
                (close) => {
                  return (
                    <div className="bg-white rounded-lg shadow-lg">
                      <Calendar
                        locale={i18n.language}
                        onChange={(value) => {
                          handleDateChange(value);
                          close();
                        }}
                        value={formData.lastApplicationDate}
                        maxDate={new Date()}
                        className="simple-calendar"
                      />
                    </div>
                  );
                }
              }
            </Popup>
          </div>

          <div>
            <label
              htmlFor="sonda-select"
              className="block mb-2 text-sm font-bold text-gray-700 dark:text-white"
            >
              {t("fieldPerformanceMeasureForm.selectSonda")}
            </label>
            <select
              id="sonda-select"
              value={formData.sondaCode ?? "0"}
              onChange={handleSondaSelectChange}
              className={`w-full px-3 py-2 border rounded-md transition-colors duration-200
                ${
                  errors.sondaCode
                    ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:border-green-500 focus:ring-green-500"
                }`}
            >
              <option disabled value="0">
                {t("fieldPerformanceMeasureForm.selectSonda")}
              </option>
              {sondas.map((sonda, idx) => (
                <option key={sonda.id} value={sonda.sondaCode}>
                  {sonda.name}
                </option>
              ))}
            </select>
            {errors.sondaCode && (
              <p className="mt-1 text-xs text-red-500 font-medium">
                {errors.sondaCode}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="rounded-md border border-transparent bg-amber-300 py-2 px-4 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
          >
            {t("fieldPerformanceMeasureForm.calculate")}
          </button>
        </form>
      </div>
      <div className="min-h-[200px] flex flex-col bg-white rounded-md p-6 space-y-6">
        {isLoading ? (
          <div className="space-y-6 animate-pulse">
            <div className="flex flex-col">
              <div className="flex items-baseline">
                <div className="h-8 w-24 bg-gray-200 rounded"></div>
                <div className="ml-2 h-6 w-16 bg-gray-200 rounded"></div>
              </div>
              <div className="mt-1 h-4 w-32 bg-gray-200 rounded"></div>
            </div>

            <div className="flex flex-col">
              <div className="flex items-baseline">
                <div className="h-10 w-20 bg-gray-200 rounded"></div>
                <div className="ml-1 h-6 w-4 bg-gray-200 rounded"></div>
              </div>
              <div className="mt-1 h-4 w-24 bg-gray-200 rounded"></div>
            </div>
          </div>
        ) : !performance && responseError ? (
          <div className="text-center py-4">
            <p className="text-sm font-normal text-rose-600">{responseError}</p>
          </div>
        ) : performance ? (
          <>
            {/* Rate Display */}
            <div className="flex flex-col">
              <div className="flex items-baseline">
                <span className="text-3xl font-semibold text-gray-900">
                  {performance.rate}
                </span>
                <span className="ml-2 text-lg font-medium text-gray-600">
                  KgN/ha
                </span>
              </div>
              <div className="mt-1 text-sm font-normal text-gray-500">
                {t("fieldPerformanceMeasureForm.since")}{" "}
                {formData.lastApplicationDate
                  ? formData.lastApplicationDate.toLocaleDateString(
                      i18n.language,
                      dateFormatOptions
                    )
                  : t("fieldPerformanceMeasureForm.lastMonth")}
              </div>
            </div>

            {/* Efficiency Rating Display */}
            <div className="flex flex-col">
              <div className="flex items-baseline">
                <span className="text-4xl font-bold text-green-600">
                  {performance.efficiency}
                </span>
                <span className="text-2xl font-semibold text-green-600 ml-1">
                  %
                </span>
              </div>
              <div className="mt-1 text-sm font-medium text-gray-500">
                {t("fieldPerformanceMeasureForm.efficiencyRating")}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center py-4">
            <p className="text-sm italic text-gray-400">
              {t("fieldPerformanceMeasureForm.helpText")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
