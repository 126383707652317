import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../";
import { axiosClient } from "../../api";
import {
  NotificationType,
  triggerNotification,
} from "../notification/notificationSlice";
import { Sonda } from "../sonda/sondaSlice";

export type Field = {
  fieldId: number;
  name: string;
  lat: number;
  lng: number;
  sondas: Sonda[];
  optimalRanges: OptimalRange[];
};

export type OptimalRange = {
  rangeId: number;
  measurementType: string;
  minValue: string;
  maxValue: string;
  unit: string;
};

interface FieldsState {
  loading: boolean;
  newField?: Field;
  saveFieldSuccess: boolean;
  deleteFieldSuccess: boolean;
  fields: Field[];
  fieldDetails?: Field;
}

const initialFieldsState: FieldsState = {
  loading: false,
  saveFieldSuccess: false,
  deleteFieldSuccess: false,
  fields: [],
  fieldDetails: undefined,
};

const fieldsSlice = createSlice({
  name: "fields",
  initialState: initialFieldsState,
  reducers: {
    clearSaveFieldSuccess: (state) => {
      state.saveFieldSuccess = false;
    },
    doSaveField: (state, action: PayloadAction<Field>) => {
      state.newField = action.payload;
      state.saveFieldSuccess = true;
    },
    removeField: (state, action: PayloadAction<number>) => {
      state.fields = state.fields.filter(
        (field) => field.fieldId !== action.payload
      );
      state.deleteFieldSuccess = true;
    },

    doFetchFields: (state, action: PayloadAction<Field[]>) => {
      state.fields = action.payload;
    },
    doFetchFieldDetails: (state, action: PayloadAction<Field>) => {
      state.fieldDetails = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    doUpdateOptimalRange: (state, action: PayloadAction<OptimalRange>) => {
      const currentRanges = state.fieldDetails?.optimalRanges;
      const newRanges = currentRanges.map((range) => {
        if (range.rangeId !== action.payload.rangeId) {
          return range;
        }
        return action.payload;
      });

      state.fieldDetails.optimalRanges = newRanges;
    },
  },
});

export const {
  clearSaveFieldSuccess,
  doSaveField,
  doFetchFields,
  doFetchFieldDetails,
  startLoading,
  stopLoading,
  doUpdateOptimalRange,
  removeField,
} = fieldsSlice.actions;
export const fieldsReducer = fieldsSlice.reducer;

export const selectNewField = (state: RootState): Field | undefined =>
  state.fields.newField;
export const selectSaveFieldSuccess = (state: RootState): boolean =>
  state.fields.saveFieldSuccess;
export const selectFields = (state: RootState): Field[] => state.fields.fields;
export const selectField = (state: RootState): Field | undefined =>
  state.fields.fieldDetails;
export const selectIsLoading = (state: RootState): boolean =>
  state.fields.loading;
export const selectUpdateOptimalRangeSuccess = (state: RootState): boolean =>
  state.fields.updateOptimalRangeSuccess;
export const selectDeleteFieldSuccess = (state: RootState): boolean =>
  state.fields.deleteFieldSuccess;

export const saveField =
  (name: string, lat: number, lng: number) => async (dispatch) => {
    const requestBody = { name, lat, lng };
    try {
      await axiosClient.post("/api/v1/fields", requestBody).then((response) => {
        dispatch(doSaveField(response.data));
        dispatch(
          triggerNotification({
            messages: ["newSondaPage.saveFieldSuccessAlert"],
            type: NotificationType.SUCCESS,
          })
        );
      });
    } catch (e) {
      dispatch(
        triggerNotification({
          messages: ["common.errorMessage"],
          type: NotificationType.ERROR,
        })
      );
    }
  };

export const fetchFields = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axiosClient.get("/api/v1/fields").then((response) => {
      dispatch(doFetchFields(response.data));
    });
  } catch (e) {
    dispatch(
      triggerNotification({
        messages: ["common.errorMessage"],
        type: NotificationType.ERROR,
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const fetchFieldDetails = (fieldId: string) => async (dispatch) => {
  try {
    await axiosClient.get(`/api/v1/fields/${fieldId}`).then((response) => {
      dispatch(doFetchFieldDetails(response.data));
    });
  } catch (e) {
    dispatch(
      triggerNotification({
        messages: ["common.errorMessage"],
        type: NotificationType.ERROR,
      })
    );
  }
};

export const updateOptimalRange =
  (rangeId: number, minValue: number, maxValue: number, type: string) =>
  async (dispatch) => {
    try {
      await axiosClient
        .put(`/api/v1/optimal_ranges/${rangeId}`, {
          min_value: minValue,
          max_value: maxValue,
        })
        .then((response) => {
          dispatch(doUpdateOptimalRange(response.data));
        });
    } catch (e) {
      let errorMessages: string[] = [];
      let response_errors = e?.response?.data?.errors;

      if (response_errors) {
        for (let field in response_errors) {
          response_errors[field].forEach((errorKey) => {
            errorMessages.push(`optimalRangeErrors.${errorKey}`);
          });
        }
      }
      dispatch(
        triggerNotification({
          messages: errorMessages,
          type: NotificationType.ERROR,
        })
      );
    }
  };

export const deleteField = (fieldId: number) => async (dispatch) => {
  try {
    await axiosClient.delete(`/api/v1/fields/${fieldId}`);
    dispatch(fetchFields());
    dispatch(removeField(fieldId));
    dispatch(
      triggerNotification({
        messages: ["fieldListPage.deleteFieldSuccessAlert"],
        type: NotificationType.SUCCESS,
      })
    );
  } catch (e) {
    dispatch(
      triggerNotification({
        messages: ["common.errorMessage"],
        type: NotificationType.ERROR,
      })
    );
  }
};
